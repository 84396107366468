<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-text-field class="c-input-small" v-model="code"
                      type="text" :label="$t('labels.detail_tracking_code')"
                      dense outlined clearable hide-details @keyup.enter="getOrder"
                      append-icon="mdi-qrcode-scan" @click:append="showQRCodeScan('code')"
        ></v-text-field>
      </v-col>

      <template v-if="order && order.id">
        <v-col cols="12" style="height: calc(100vh - 185px); overflow: auto">
          <v-row>
            <v-col md="4" cols="12">
              <v-simple-table dense class="table-bordered">
                <template v-slot:default>
                  <thead class="v-data-table-header">
                  <tr>
                    <th role="columnheader" class="text-center" style="width: 30%">{{ order.pos_code }}</th>
                    <th role="columnheader" class="text-center" style="width: 30%">{{ order.delivery_short_name }}</th>
                    <th role="columnheader" class="text-center">{{ order.company_name }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr class="text-center">
                    <td>{{ order.tracking_id }}</td>
                    <td>
                      <div class="d-flex align-center justify-center">
                        <DeliveryTracking :order="order" />
                        <v-btn v-if="checkPermission(['goods_issue_update_delivery']) && canChangeDelivery"
                               class="cursor-pointer ml-2 mr-1" color="warning" @click="showChangeDelivery" x-small>{{ $t('labels.change_delivery') }}</v-btn>
                        <v-btn v-if="checkPermission(['goods_issue_update_delivery']) && canReGetDelivery"
                               class="white--text" color="blue lighten-1" @click="reGetDelivery" x-small>{{ $t('labels.get_delivery') }}</v-btn>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex align-center justify-center">
                        {{ order.customer_order_id }}
                      </div>
                    </td>
                  </tr>
                  <tr class="text-center">
                    <td class="error--text font-weight-medium">
                      {{ order.state_name }}
                      <div v-if="order.request_cancel" class="mt-1">
                        <v-chip color="red" text-color="white" small>{{ $t('labels.cancelled') }}</v-chip>
                      </div>
                    </td>
                    <td>{{ order.delivery_partner_state }}</td>
                    <td class="error--text font-weight-medium">{{ order.delivery_state_name }}</td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col md="8" cols="12">
              <v-simple-table dense class="table-bordered">
                <template v-slot:default>
                  <thead class="v-data-table-header">
                  <tr>
                    <th role="columnheader" class="text-center">{{ $t('labels.name') }}</th>
                    <th role="columnheader" class="text-center">{{ $t('labels.phone_number') }}</th>
                    <th role="columnheader" class="text-center">{{ $t('labels.city') }}</th>
                    <th role="columnheader" class="text-center">{{ $t('labels.district') }}</th>
                    <th role="columnheader" class="text-center">{{ $t('labels.ward') }}</th>
                    <th role="columnheader" class="text-center">{{ $t('labels.address') }}</th>
                    <th role="columnheader" class="text-center">{{ $t('labels.cod') }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr class="text-center">
                    <td>{{ replaceTxtStar(order.receiver_name) }}</td>
                    <td>{{ replaceTxtStar(order.receiver_phone_number) }}</td>
                    <td class="text-capitalize">{{ order.city_name }}</td>
                    <td class="text-capitalize">{{ order.county_name }}</td>
                    <td class="text-capitalize">{{ order.ward_name }}</td>
                    <td class="text-capitalize" style="width: 20%">{{ replaceTxtStar(order.address) }}</td>
                    <td>{{ formatNumber(order.final_cod) }}</td>
                  </tr>
                  <tr v-if="canViewBill">
                    <td colspan="7">
                      {{ $t('labels.order_prepay') }}:
                      <span class="primary--text cursor-pointer text-decoration-underline font-weight-medium" @click="showBillImages">{{ formatNumber(order.prepay) }}</span>.
                      {{ $t('labels.order_bank_account') }}: <span class="font-weight-medium">{{ order.bank_account }}</span> - {{ order.bank_name }}
                    </td>
                  </tr>
                  <!--<tr>
                    <td colspan="7">
                      <div class="d-flex align-center flex-wrap pa-1">
                        {{ $t('labels.tracking_same_phone') }}:
                        <template v-if="order.tracking_same_phone && order.tracking_same_phone.length > 0">
                          <span>
                            <span v-for="(otem, okey) in order.tracking_same_phone" :key="`${okey}_${otem}`">
                              <OrderTracking :tracking-id="otem" v-if="okey < (showSameTrackingAll ? order.tracking_same_phone.length : 10 )" class="px-2 py-1"  />
                            </span>
                            <v-chip small class="px-2 py-1 cursor-pointer" v-if="order.tracking_same_phone.length > 10" @click="switchSameTrackingAll">
                              {{ showSameTrackingAll ? $t('views.compact') : $t('views.full') }}
                            </v-chip>
                          </span>
                        </template>
                      </div>
                      
                    </td>
                  </tr>-->
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>

            <v-col cols="12" md="4">
              <v-simple-table dense class="table-bordered">
                <template v-slot:default>
                  <thead class="v-data-table-header">
                  <tr>
                    <th role="columnheader" class="text-center" style="width: 30%">{{ $t('labels.create_method') }}</th>
                    <th role="columnheader" class="text-center" style="width: 30%">{{ $t('labels.e_market') }}</th>
                    <th role="columnheader" class="text-center">{{ $t('labels.shop') }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr class="text-center">
                    <td>{{ order.create_method_name }}</td>
                    <td>{{ order.e_marketplace_short_name }}</td>
                    <td>{{ order.customer_e_marketplace_shop_name }}</td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col cols="12" md="8">
              <v-simple-table dense class="table-bordered">
                <template v-slot:default>
                  <thead class="v-data-table-header">
                  <tr>
                    <th role="columnheader" class="text-center">{{ $t('labels.number_of_goods') }}</th>
                    <th role="columnheader" class="text-center">{{ $t('labels.quantity') }}</th>
                    <th role="columnheader" class="text-center">{{ $t('labels.total_pre_weight') }}</th>
                    <th role="columnheader" class="text-center">{{ $t('labels.total_weight') }}</th>
                    <th role="columnheader" class="text-center">{{ $t('labels.cod_1') }}</th>
                    <th role="columnheader" class="text-center">{{ $t('labels.note') }}</th>
                    <th role="columnheader" class="text-center">{{ $t('labels.cod_2') }}</th>
                    <th role="columnheader" class="text-center" v-if="order.indemnify_amount">{{ $t('labels.indemnify') }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr class="text-center">
                    <td>{{ items.length }}</td>
                    <td>{{ itemSum.sumItem }}</td>
                    <td>{{ formatNumber(itemSum.sumPreWeight) }}g</td>
                    <td>{{ formatNumber(itemSum.sumWeight) }}g</td>
                    <td>{{ formatNumber(order.cash_on_delivery) }}</td>
                    <td>{{ order.note }}</td>
                    <td>{{ formatNumber(order.money_collected) }}</td>
                    <td class="warning--text" v-if="order.indemnify_amount">{{ formatNumber(order.indemnify_amount) }} ({{ order.indemnify_note }})</td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>

            <v-col cols="12" md="4">
              <!--<v-simple-table dense class="table-bordered mb-5">
                <template v-slot:default>
                  <tbody>
                  <tr class="text-center">
                    <td style="width: 30%"><b class="warning--text">{{ $t('menus.internal_request') }}</b></td>
                    <td style="width: 30%">
                      <InternalRequestOrderLink :text="$t('labels.internal_request_status_1')" :count="order.count_request_waiting_process" :tracking_id="order.tracking_id" :status="1" />
                    </td>
                    <td>
                      <InternalRequestOrderLink :text="$t('labels.internal_request_status_2')" :count="order.count_request_processing" :tracking_id="order.tracking_id" :status="2" />
                    </td>
                  </tr>
                  <tr class="text-center">
                    <td>
                      <InternalRequestOrderLink :text="$t('labels.internal_request_status_3')" :count="order.count_request_waiting_confirm" :tracking_id="order.tracking_id" :status="3" />
                    </td>
                    <td>
                      <InternalRequestOrderLink :text="$t('labels.internal_request_status_4')" :count="order.count_request_done" :tracking_id="order.tracking_id" :status="4" />
                    </td>
                    <td>
                      <InternalRequestOrderLink :text="$t('labels.internal_request_status_5')" :count="order.count_request_cancel" :tracking_id="order.tracking_id" :status="5" />
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>-->

              <v-simple-table dense class="table-bordered">
                <template v-slot:default>
                  <thead class="v-data-table-header">
                  <tr>
                    <th role="columnheader" class="text-center" style="width: 30%">{{ $t('labels.status') }}</th>
                    <th role="columnheader" class="text-center" style="width: 30%">{{ $t('labels.time') }}</th>
                    <th role="columnheader" class="text-center">{{ $t('labels.user_process') }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr class="text-center">
                    <td>{{ $t('labels.order_create') }}</td>
                    <td>{{ formatDateTime(order.create_time, 'HH:mm:ss DD/MM/YYYY') }}</td>
                    <td>
                      {{ order.create_by_name }}
                    </td>
                  </tr>
                  <tr class="text-center" v-if="order.change_item_by_name">
                    <td>NV đổi hàng</td>
                    <td></td>
                    <td>{{ order.change_item_by_name || '' }}</td>
                  </tr>
                  <tr class="text-center">
                    <td>{{ $t('labels.order_status_40') }}</td>
                    <td>{{ order.receipt_at ? formatDateTime(order.receipt_at, 'HH:mm:ss DD/MM/YYYY') : '' }}</td>
                    <td></td>
                  </tr>
                  <tr class="text-center" v-if="order.stamp_created_at">
                    <td>{{ $t('labels.order_stamp_create') }}</td>
                    <td>{{ order.stamp_created_at ? formatDateTime(order.stamp_created_at, 'HH:mm:ss DD/MM/YYYY') : '' }}</td>
                    <td></td>
                  </tr>
                  <tr class="text-center">
                    <td>{{ $t('labels.order_status_60') }}</td>
                    <td>
                      {{ order.handling_at ? formatDateTime(order.handling_at, 'HH:mm:ss DD/MM/YYYY') : '' }}
                      <div v-if="order.id_pickup_command" class="error--text">{{ order.id_pickup_command }}</div>
                    </td>
                    <td>
                      {{ order.handling_by_name }}
                      <div v-if="order.id_pickup_command" class="error--text">{{ pickup.basket_index }} - {{ pickup.basket_code }}</div>
                    </td>
                  </tr>
                  <tr class="text-center">
                    <td>{{ $t('labels.order_status_100') }}</td>
                    <td>{{ order.pickup_at ? formatDateTime(order.pickup_at, 'HH:mm:ss DD/MM/YYYY') : '' }}</td>
                    <td>{{ order.pickup_by_name }}</td>
                  </tr>
                  <tr class="text-center">
                    <td>{{ $t('labels.order_packing_scan') }}</td>
                    <td>{{ order.start_packing_at ? formatDateTime(order.start_packing_at, 'HH:mm:ss DD/MM/YYYY') : '' }}</td>
                    <td></td>
                  </tr>
                  <tr class="text-center">
                    <td>{{ $t('labels.order_packing_print') }}</td>
                    <td>{{ order.start_packing_print_stamp_at ? formatDateTime(order.start_packing_print_stamp_at, 'HH:mm:ss DD/MM/YYYY') : '' }}</td>
                    <td></td>
                  </tr>
                  <tr class="text-center">
                    <td>{{ $t('labels.order_status_160') }}</td>
                    <td>{{ order.package_at ? formatDateTime(order.package_at, 'HH:mm:ss DD/MM/YYYY') : '' }}</td>
                    <td>{{ order.package_by_name }}</td>
                  </tr>
                  <tr class="text-center">
                    <td>{{ $t('labels.order_packing_table') }}</td>
                    <td>{{ order.packing_table_code || '' }}</td>
                    <td></td>
                  </tr>
                  <tr class="text-center">
                    <td>{{ $t('labels.order_handover_scan') }}</td>
                    <td>{{ order.order_handover_scan_at ? formatDateTime(order.order_handover_scan_at, 'HH:mm:ss DD/MM/YYYY') : '' }}</td>
                    <td>{{ order.order_handover_employee || '' }}</td>
                  </tr>
                  <tr class="text-center">
                    <td>
                      {{ $t('labels.order_status_200') }}
                      <template v-if="handover.id">
                        <br>
                        <HandoverImage :item="handover"/>
                        <v-btn class="ml-1" v-if="checkRoleUpDownload(handover)" x-small color="success" outlined @click="downloadHandoverFile(handover)">
                          <img src="@/assets/common/download.png" style="height: 16px" alt="">
                        </v-btn>
                      </template>
                    </td>
                    <td>{{ order.hand_over_at ? formatDateTime(order.hand_over_at, 'HH:mm:ss DD/MM/YYYY') : '' }}</td>
                    <td>{{ order.hand_over_by_name }}</td>
                  </tr>
                  <tr class="text-center">
                    <td>{{ $t('labels.order_status_220') }}</td>
                    <td>{{ order.confirm_return_at ? formatDateTime(order.confirm_return_at, 'HH:mm:ss DD/MM/YYYY') : '' }}</td>
                    <td>{{ order.confirm_return_by_name }}</td>
                  </tr>
                  <tr class="text-center" v-if="order.goods_return_note">
                    <td colspan="3">
                      <span v-if="order.goods_return_seal_status === 0" class="error--text">{{ $t('labels.lost_seal') }} - </span>
                      <span v-if="order.goods_return_is_wet_or_damaged === 1" class="error--text">{{ $t('labels.is_damage') }} - </span>
                      <span class="warning--text">{{ order.goods_return_note }}</span>
                    </td>
                  </tr>
                  <tr class="text-center">
                    <td>{{ $t('labels.order_status_240') }}</td>
                    <td>{{ order.receipt_return_at ? formatDateTime(order.receipt_return_at, 'HH:mm:ss DD/MM/YYYY') : '' }}</td>
                    <td>{{ order.receipt_return_by_name }}</td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col cols="12" md="8">
              <v-simple-table dense class="table-bordered">
                <template v-slot:default>
                  <thead class="v-data-table-header">
                  <tr>
                    <th role="columnheader" class="text-center">{{ $t('labels.sku') }}</th>
                    <th role="columnheader" class="text-center">{{ $t('labels.barcode') }}</th>
                    <th role="columnheader" class="text-center" style="width: 25%">{{ $t('labels.goods') }}</th>
                    <th role="columnheader" class="text-center">{{ $t('labels.goods_description') }}</th>
                    <th role="columnheader" class="text-center">{{ $t('labels.size') }}</th>
                    <th role="columnheader" class="text-center">{{ $t('labels.position') }}</th>
                    <th role="columnheader" class="text-center">{{ $t('labels.available') }}</th>
                    <th role="columnheader" class="text-center">{{ $t('labels.export') }}</th>
                    <th role="columnheader" class="text-center">{{ $t('labels.uid') }}/{{ $t('labels.barcode') }}</th>
                    <th role="columnheader" class="text-center">{{ $t('labels.return_quantity') }}</th>
                    <!-- <th role="columnheader" class="text-center">{{ $t('labels.uid') }}/{{ $t('labels.barcode') }}</th> -->
                    <th role="columnheader" class="text-center">{{ $t('labels.return_note') }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr class="text-center" :class="{'text-decoration-line-through grey--text': item.soft_delete}" v-for="(item, key) in items" :key="`goods_${item.id_goods}_${key}`">
                    <td>{{ item.sku }}</td>
                    <td>{{ item.customer_goods_barcode }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.description }}</td>
                    <td>{{ item.size }}</td>
                    <td>{{ item.storage_location }}</td>
                    <td>{{ item.available }}</td>
                    <td>{{ formatNumber(item.sum_quantity) }}</td>
                    <!-- <td><div style="white-space: pre-line" v-html="uidHtml(item)"></div></td> -->
                    <td><span class="info--text" style="white-space: pre-line">{{ item.uids || '' }}</span></td>
                    <td>{{ order.state === 240 ? formatNumber(item.return_quantity) : '' }}</td>
                    <!-- <td><span class="info--text" style="white-space: pre-line">{{ item.return_uids || '' }}</span></td> -->
                    <td>{{ item.return_note }}</td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row>
            <v-col v-if="!!order.stamp_created_at">
              <v-btn color="success" small block @click="printStamp">{{ $t('labels.print_stamp') }}</v-btn>
            </v-col>
            <v-col v-if="isOrderDeliveryApi">
              <v-btn color="success" small block @click="showLogDialog('delivery-status')">{{ $t('labels.log_delivery_status') }}</v-btn>
            </v-col>
            <v-col>
              <v-btn color="success" small block @click="showLogDialog('package')">{{ $t('labels.log_package') }}</v-btn>
            </v-col>
            <v-col v-if="checkPermission(['goods_issue_system_log'])">
              <v-btn color="warning" small block @click="showLogDialog('system')">{{ $t('labels.log_system') }}</v-btn>
            </v-col>
            <v-col v-if="isOrderDeliveryApi && checkPermission(['goods_issue_delivery_request_log'])">
              <v-btn color="warning" small block @click="showLogDialog('delivery-request')">{{ $t('labels.log_request_delivery') }}</v-btn>
            </v-col>
            <v-col v-if="isOrderDeliveryApi && checkPermission(['goods_issue_delivery_webhook_log'])">
              <v-btn color="warning" small block @click="showLogDialog('delivery-webhook')">{{ $t('labels.log_webhook_delivery') }}</v-btn>
            </v-col>
            <v-col v-if="checkPermission(['goods_issue_cost_log'])">
              <v-btn color="warning" small block @click="showLogDialog('cost')">{{ $t('labels.log_cost') }}</v-btn>
            </v-col>
            <v-col v-if="checkPermission(['goods_issue_excel_create']) && canDownloadFileCreate">
              <v-btn color="warning" small block @click="downloadExcelCreate">{{ $t('labels.excel_create') }}</v-btn>
            </v-col>
            <v-col v-if="checkPermission(['goods_issue_send_webhook'])">
              <v-btn color="error" small block @click="sendWebhook">{{ $t('labels.send_webhook') }}</v-btn>
            </v-col>
            <v-col v-if="checkPermission(['goods_issue_indemnify']) && canIndemnify">
              <v-btn color="error" small block @click="showDialogIndemnify">{{ $t('labels.indemnify') }}</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </template>

    </v-row>

    <v-dialog v-model="dialogSelectOrder" persistent max-width="900px">
      <SelectOrderForDetail v-if="dialogSelectOrder && orders.length > 0" :orders="orders" @cancel="hideSelectOrderDialog" @selectOrder="selectOrder" />
    </v-dialog>

    <v-dialog v-model="dialogUpdateDelivery" persistent max-width="350px">
      <UpdateDeliveryCompany
          v-if="dialogUpdateDelivery"
          :order-id="order.id"
          :id-pos="order.id_pos"
          :id-customer="order.id_customer"
          @cancel="hideChangeDelivery"
      />
    </v-dialog>

    <v-dialog v-model="dialogIndemnify" persistent max-width="350px">
      <Indemnify
          v-if="dialogIndemnify"
          :order-id="order.id"
          @cancel="hideDialogIndemnify"
          @refreshData="refreshData"
      />
    </v-dialog>

    <v-dialog v-model="dialogLog" :max-width="['package', 'cost'].includes(dialogLogType) ? '600px' : '1200px'" v-if="order && order.id">
      <LogOrderSystem :id-order="order.id" v-if="dialogLog && dialogLogType === 'system'" @cancel="hideLogDialog" />
      <LogOrderDeliveryStatus :id-order="order.id" v-if="dialogLog && dialogLogType === 'delivery-status'" @cancel="hideLogDialog" />
      <LogOrderDeliveryRequest :id-order="order.id" v-if="dialogLog && dialogLogType === 'delivery-request'" @cancel="hideLogDialog" />
      <LogOrderDeliveryWebhook :id-order="order.id" v-if="dialogLog && dialogLogType === 'delivery-webhook'" @cancel="hideLogDialog" />
      <LogOrderPackage :id-order="order.id" v-if="dialogLog && dialogLogType === 'package'" @cancel="hideLogDialog" />
      <LogOrderCost :id-order="order.id" v-if="dialogLog && dialogLogType === 'cost'" @cancel="hideLogDialog" />
    </v-dialog>

    <v-dialog v-model="qrScanDialog" max-width="100vw">
      <QRCodeScanner v-if="qrScanDialog" :name="qrScanType" :auto-close="true" @close="hideQRCodeScan" @onScanned="onQRScanned" />
    </v-dialog>
  </div>
</template>

<script>
import {httpClient} from "@/libs/http";
import moment from "moment";
import {api as viewerApi} from "v-viewer";

export default {
  name: "Detail",
  components: {
    // InternalRequestOrderLink: () => import('@/components/internal_requests/OrderLink'),
    LogOrderPackage: () => import('@/components/order_online/LogOrderPackage'),
    LogOrderCost: () => import('@/components/order_online/LogOrderCost'),
    LogOrderSystem: () => import('@/components/order_online/LogOrderSystem'),
    LogOrderDeliveryStatus: () => import('@/components/order_online/LogOrderDeliveryStatus'),
    LogOrderDeliveryRequest: () => import('@/components/order_online/LogOrderDeliveryRequest'),
    LogOrderDeliveryWebhook: () => import('@/components/order_online/LogOrderDeliveryWebhook'),
    UpdateDeliveryCompany: () => import('@/components/order_online/UpdateDeliveryCompany'),
    SelectOrderForDetail: () => import('@/components/order_online/SelectOrderForDetail'),
    Indemnify: () => import('@/components/order_online/Indemnify'),
    HandoverImage: () => import('@/components/order_online/HandoverImage'),
    // OrderTracking: () => import('@/components/common/OrderTracking'),
    DeliveryTracking: () => import('@/components/common/DeliveryTracking'),
    QRCodeScanner: () => import('@/components/common/QRCodeScanner'),
  },
  data: () => ({
    isLoading: false,
    code: null,
    id: null,
    order: {},
    pickup: {},
    orders: [],
    items: [],
    dialogUpdateDelivery: false,
    dialogSelectOrder: false,
    dialogLog: false,
    dialogLogType: null,
    dialogIndemnify: false,
    showSameTrackingAll: false,
    handover: {},
    qrScanDialog: false,
    qrScanType: null,
  }),
  computed: {
    canViewBill() {
      if (!this.order || !this.order.id || !this.order.prepay) {
        return false
      }
      if (!this.order.bill_images || this.order.bill_images.length === 0) {
        return false
      }
      if (!this.checkPermission(['pos_order_detail_bill_view'])) {
        return false
      }
      return true
    },
    isOrderDeliveryApi() {
      if (!this.order || !this.order.id) {
        return false
      }
      return !!([1, 2, 5].includes(this.order.create_method) && this.order.id_delivery_contract)
    },
    canChangeDelivery() {
      if (!this.order || !this.order.id) {
        return false
      }
      return this.isOrderDeliveryApi //&& this.order.state === 35 && this.order.state < 200
    },
    canReGetDelivery() {
      if (!this.order || !this.order.id) {
        return false
      }
      return this.isOrderDeliveryApi && this.order.state === 35
    },
    canDownloadFileCreate() {
      if (!this.order || !this.order.id) {
        return false
      }
      return [2,3].includes(this.order.create_method)
    },
    canIndemnify() {
      if (!this.order || !this.order.id) {
        return false
      }
      if (this.order.state < 200) {
        return false
      }
      if (this.order.id_delivery_state === 800) {
        return false
      }
      if (!this.isOrderDeliveryApi) {
        return false
      }
      if ([400].includes(this.order.id_delivery_state) || ([401, 402, 403].includes(this.order.id_delivery_state) && this.order.state === 240)) {
        const today = moment().startOf('day').unix()
        const finish_delivery_time = this.order.finish_delivery_time ? moment(this.order.finish_delivery_time).startOf('day').unix() : null
        if (today !== finish_delivery_time) {
          return false
        }
      }
      return true
    },
    itemSum() {
      let sumWeight = 0
      let sumPreWeight = 0
      let sumItem = 0
      if (!this.items || this.items.length === 0) {
        return {
          sumWeight,
          sumPreWeight,
          sumItem
        }
      }
      this.items.forEach(item => {
        sumWeight += +item.total_weight
        sumPreWeight += +item.total_pre_weight
        sumItem += +item.sum_quantity
      })
      return {
        sumWeight,
        sumPreWeight,
        sumItem
      }
    },
  },
  mounted() {
    const id = this.getUrlParameter('id')
    if (id) {
      this.id = id
    }
    const code = this.getUrlParameter('tracking_id')
    if (code) {
      this.code = code
    }
    if (this.id || this.code) {
      this.getOrder()
    }
    this.listenSocket()
  },
  methods: {
    showBillImages() {
      const index = 0
      const imageUrls = [...this.order.bill_images].map(img => (`${process.env.VUE_APP_FILE_CDN_URL}/${img}`))
      viewerApi({
        options: {
          toolbar: true,
          initialViewIndex: index
        },
        images: imageUrls
      })
    },
    showQRCodeScan(type) {
      this.qrScanDialog = true
      this.qrScanType = type
    },
    hideQRCodeScan() {
      this.qrScanDialog = false
      this.qrScanType = null
    },
    onQRScanned(filter) {
      this[filter.name] = filter.value
      this.getOrder()
    },
    switchSameTrackingAll() {
      this.showSameTrackingAll = !this.showSameTrackingAll
    },
    uidHtml(item) {
      const groupUids = []
      for (let g = 0; g < item.groups.length; g++) {
        const group = item.groups[g]
        const groupUid = []
        if (group.code) {
          groupUid.push(`<span>${group.code}</span>`)
        }
        if (group.uids) {
          groupUid.push(`<span class="info--text">${group.uids}</span>`)
        }
        groupUids.push(groupUid.join(': '))
      }
      return groupUids.join('\n')
    },
    async printStamp() {
      const filename = `100x80_${this.order.tracking_id}.pdf`
      this.downloadExcelFile('/order-online-print-stamp', {tracking_id: this.order.tracking_id}, filename)
    },
    async downloadExcelCreate() {
      const filename = `order-${this.order.tracking_id}`
      this.downloadExcelFile('/order-online-download-file-create', {id: this.order.id_goods_issue}, filename)
    },
    async sendWebhook() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t('messages.loading'));
        return false
      }
      this.isLoading = true

      try {
        await httpClient.post('/order-online-send-webhook', {
          id_order: this.order.id,
        })
        this.isLoading = false
        this.$vToastify.success('Đã gửi Webhook');
      } catch (e) {
        const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
        this.$vToastify.error(errMsg);
        this.isLoading = false
        this.$root.$emit('playErrorAudio')
      }
    },
    refreshData(id) {
      this.id = id
      this.getOrder()
    },
    async getOrder() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t('messages.loading'));
        return false
      }
      this.isLoading = true
      this.order = {}
      this.orders = []
      this.items = []
      this.pickup = {}

      try {
        const {data} = await httpClient.post('/order-online-by-code', {
          code: this.code,
          id: this.id,
        })
        if (!data || data.length === 0) {
          this.$vToastify.error(`Không tìm thấy đơn này với mã ${this.code}`);
          this.isLoading = false
          this.code = null
          this.id = null
          this.$root.$emit('playErrorAudio')
          return false
        }
        this.orders = [...data]
        if (data.length === 1) {
          await this.selectOrder(data[0].id)
        } else {
          this.showSelectOrderDialog()
        }
        this.isLoading = false
        this.code = null
        this.id = null
        // this.$root.$emit('playSuccessAudio')
      } catch (e) {
        const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
        this.$vToastify.error(errMsg);
        this.isLoading = false
        this.code = null
        this.id = null
        this.$root.$emit('playErrorAudio')
      }
    },
    async selectOrder(id) {
      const order = [...this.orders].find(i => i.id === id)
      if (order.tracking_same_phone) {
        order.tracking_same_phone = order.tracking_same_phone.split(';')
      }
      this.order = {...order}
      const dataItems = await httpClient.post('/order-online-get-items', {
        id_order: this.order.id,
      })
      this.items = [...(dataItems.data || [])]

      if (this.order.id_pickup_command) {
        const dataPickup = await httpClient.post('/order-online-get-pickup', {
          id_order: this.order.id,
        })
        this.pickup = {...(dataPickup.data || {})}
      }

      if (this.order.tracking_id) {
        const dataHandover = await httpClient.post('/order-online-get-handover', {order_tracking_id: this.order.tracking_id, page: 1})
        if (dataHandover && dataHandover.data && dataHandover.data.rows && dataHandover.data.rows[0]) {
          this.handover = {...dataHandover.data.rows[0]}
        }
      }
    },

    showChangeDelivery() {
      this.dialogUpdateDelivery = true
    },
    hideChangeDelivery() {
      this.dialogUpdateDelivery = false
    },

    showLogDialog(type) {
      this.dialogLog = true
      this.dialogLogType = type
    },
    hideLogDialog() {
      this.dialogLog = false
      this.dialogLogType = null
    },

    showSelectOrderDialog() {
      this.dialogSelectOrder = true
    },
    hideSelectOrderDialog() {
      this.dialogSelectOrder = false
    },

    showDialogIndemnify() {
      this.dialogIndemnify = true
    },
    hideDialogIndemnify() {
      this.dialogIndemnify = false
    },

    async reGetDelivery() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t('messages.loading'));
        return false
      }
      this.isLoading = true

      const orderIds = [this.order.id]
      try {
        await httpClient.post('/order-online-re-get-delivery-order-id', {
          orderIds,
        })
        this.isLoading = false
        this.$root.$emit('playSuccessAudio')
        this.$vToastify.success(this.$t('messages.request_re_get_delivery_success'));
      } catch (e) {
        const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
        this.$vToastify.error(errMsg);
        this.isLoading = false
        this.$root.$emit('playErrorAudio')
      }
    },

    listenSocket () {
      window.socket.on('delivery_tracking_updated', data => {
        if (this.order && this.order.id && this.order.id == data.id) {
          this.code = data.tracking_id
          this.getOrder()
        }
      })
    },

    checkRoleUpDownload(item) {
      if (!window.me || !window.me.employee || !window.me.employee.id) {
        return false
      }
      if (this.checkPermission(['super_admin'])) {
        return true
      }
      const employeeId = window.me.employee.id
      return employeeId === item.id_employee
    },
    downloadHandoverFile(item) {
      this.downloadExcelFile('/order-online-handover-file', {id_handover: item.id}, 'A4_Bien_ban_ban_giao.xlsx')
    },
  },
}
</script>

<style scoped>

</style>
